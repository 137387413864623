<template>
  <div class="job-config-master-container">
    <Notification />
    <h1>Job Config</h1>
    <h2 v-if="!jobConfigLoading && jobConfig.definition!==undefined" class="job-config-h2">{{jobConfig.definition.name}}</h2>
    <div v-if="jobConfigLoading">
      <h2><font-awesome-icon :icon="['fas', 'spinner']"/></h2>
      <h2 class="job-config-h2">Details</h2>
      <div>
        <div class="job-config-row">
          <div class="col">
            <div><strong>Name: </strong><font-awesome-icon :icon="['fas', 'spinner']"/></div>
            <div><strong>Version: </strong>   <font-awesome-icon :icon="['fas', 'spinner']"/></div>  
          </div>
          <div class="col">
            <div><strong>Source: </strong><font-awesome-icon :icon="['fas', 'spinner']"/></div>  
            <div><strong>Type: </strong><font-awesome-icon :icon="['fas', 'spinner']"/></div>
          </div>
        </div>
      </div>
      <h2 class="job-config-h2">Variables</h2> 
      <table id="job-config-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Source</th>
            <th>Type</th>
            <th>Version</th>
          </tr>
        </thead>
        <tbody v-if="jobConfig==undefined && !jobConfigLoading">        
          <tr>
            <td><font-awesome-icon :icon="['fas', 'spinner']"/></td>  
            <td><font-awesome-icon :icon="['fas', 'spinner']"/></td>
            <td><font-awesome-icon :icon="['fas', 'spinner']"/></td>
            <td><font-awesome-icon :icon="['fas', 'spinner']"/></td>
          </tr>
        </tbody>
      </table>           
    </div>
    <div v-if="!jobConfigLoading && jobConfig!==undefined && jobConfig.definition!==undefined"  class="job-config-container">
      <h2 class="job-config-h2">Details</h2>
      <form @submit.prevent="saveJobConfig">
        <button type="submit" class="app-button primary-button">Save Job Configuration</button>
        <button type="button" class="app-button secondary-button" @click="resetAllJobOVerrides">Clear Job Overrides</button>
        <div class="job-config-row">
          <div class="col">
            <div><strong>Name: </strong>{{ jobConfig.definition.name  }} </div>
            <div class="job-config-input">
              <strong><label for="job-config-version">Version: </label></strong>
              <input type="text" v-model="jobConfig.definition.version" name="param-name-input" class="form-control" @change="handleUpdateVersion"/> 
            <h2 v-if="jobConfig.definition.source==='job_override'" @click="() => handleRemoveJobVersionOverride(variable)"><font-awesome-icon :icon="['fas', 'trash']"/></h2>
            </div>
          </div>
          <div class="col">
            <div><strong>Source: </strong>{{ jobConfig.definition.source  }} </div>
            <div><strong>Type: </strong>{{ jobConfig.definition.type  }}</div>
          </div>
        </div>
        <h2 class="job-config-h2">Variables</h2> 
        <table id="job-config-table">
          <thead>
            <tr>
              <td>{{ this.hide? "Add New Job Override":"Close" }}
                <Collapser @toggleElement="toggleElement" :hide="this.hide"/>
              </td>
            </tr>
            <tr>
              <th>Name</th>
              <th>Source</th>
              <th>Type</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>    
            <tr v-if="!hide">
              <td><input type="text" v-model="newOverride.name" class="form-control"/></td>
              <td>job_override</td>
              <td>str</td>
              <td>
                <input type="text" v-model="newOverride.value" class="form-control"/>
                <button v-if="newOverride.name!==undefined && newOverride.value!==undefined" @click="addNewOverride" type="button" class="app-button primary-button">Add New Override</button>
              </td>
            </tr>  
            <tr v-if="addingNewOverride"><font-awesome-icon :icon="['fas', 'spinner']"/></tr>
            <tr v-for="variable, index in jobConfig.variables" :key="index">
              <td>{{variable.name}}</td>  
              <td>{{variable.source}}</td>
              <td>{{variable.type}}</td>
              <td v-if="(variable.source==='job_var' || variable.source==='step_override' || variable.source==='job_override') && variable.type!=='ReplaceOperation' && variable.type!=='str_sub'  && variable.options===undefined">
                <div class="job-config-input">
                  <input type="text" v-model="variable.value" class="form-control" @change="(value) => handleUpdateValue({value, variable})"/>
                </div>
              </td>
              <td v-if="(variable.source!=='job_var' || variable.type==='ReplaceOperation' || variable.type==='str_sub') && variable.source!=='step_override' && variable.source!=='job_override' && variable.options===undefined">{{variable.value}}</td>
              <td v-if="variable.options!==undefined">
                <select id="filter"
                        name="filter"
                        class="form-control mt-1"
                        v-model="variable.value"
                        @change="(value) => handleUpdateValue({value, variable})">
                  <option v-for="item, index in variable.options" v-bind:value="item" :key="index">{{ item }}</option>
                </select>
              </td>
              <td class="remove-job-override" v-if="variable.source==='job_override'" @click="() => handleRemoveJobOverride(variable)">
                <h2><font-awesome-icon :icon="['fas', 'trash']"/></h2>
              </td>
            </tr>
            <tr v-if="addingNewOverride"><font-awesome-icon :icon="['fas', 'spinner']"/></tr>
          </tbody>
        </table>
        <button type="submit" class="app-button primary-button">Save Job Configuration</button>
      </form>
    </div>
  </div>  
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import SelectList from '../Generics/SelectList.vue'
import Collapser from '../Generics/Collapser.vue'
import Notification from '../Generics/Notification.vue';


export default {
  name: 'JobConfigEditable',
  components: {
    SelectList,
    Notification,
    Collapser
  },
  props: {
    project: String,
    workflow_id: String,
    step: String,
    job_id: String,
  },
  data() {
    return {
      hide:true,
      newOverride: {},
    }
  },
  computed: {
    ...mapGetters({
      jobConfig: 'getJobConfig',
      jobConfigLoading: 'jobConfigLoading',
      variablesToOverride: 'getJobOverrides',
      addingNewOverride: 'getAddingNewOverride'
    })
  },
  methods: {
    ...mapActions([
      'loadJobConfig', 
      'updateJobConfig', 
      'saveJobConfig', 
      'addJobConfigOverride', 
      'removeJobOverride',
      'resetAllJobOVerrides',
      'resetJobVersionOverride',
      'updateJobVersion'
    ]),
    async handleUpdateValue(selection){
      await this.updateJobConfig({"name":selection.variable.name.toUpperCase(), "value": selection.value.target.value})
    },
    async handleSaveJobConfig(){
      await this.saveJobConfig()
    },
    async handleRemoveJobVersionOverride(){
      await this.resetJobVersionOverride()
    },
    async handleUpdateVersion(){
      await this.updateJobVersion()
    },
    toggleElement(){
      this.hide = !this.hide
    },
    addNewOverride(){
      this.addJobConfigOverride(this.newOverride)
      this.toggleElement()
      window.scrollTo(0, document.body.scrollHeight);
    },
    handleRemoveJobOverride(variable){
      this.removeJobOverride(variable)
    }
  },
  async mounted(){
    let args = {project: this.project, workflow_id: this.workflow_id, step: this.step, job_id: this.job_id}
    await this.loadJobConfig(args)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
a {
  text-decoration: none;
}

.loading-component {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
    width: 100px;
    height: 100px;
}

.col{
    flex:1;
    flex-wrap: wrap;
    justify-content: flex-start;
    flex-direction: column;
    box-sizing: border-box;
    line-height: 2.5;
  }
  
  .job-config-container {
    text-align: center;
  }

  .job-config-row{
    display: flex;
    text-align: center;
    border: 0.5px solid lightgray;
    width:90%;
    margin-left:5%
  }

  .job-config-input{
    display: flex;
    text-align: center;
  }

  .job-config-row:nth-child(odd) {
  background-color: rgb(0, 75, 100, 0.05);
  }
  .job-config-row:hover{
    background-color: rgba(100, 105, 110, 0.1);
    cursor:pointer;
  }

  .job-config-h2 {
    padding-top:0.5rem;
    padding-bottom:1rem;
    margin:1rem;
    background: rgba(0, 0, 0, 0.01);
    border:0.1rem solid rgba(0, 0, 0, 0.03);
    border-radius:0.25rem;
    color:rgb(50, 50, 50)
  }

.job-config-master-container{
  padding-bottom:5rem;
}

.remove-job-override{
  cursor: pointer;
}

#filter{
  width: 100%;
  line-height: 1.5;
  text-align: center;
  height: 2rem;
  font-size: 16px;
  background-color: rgb(255, 255, 255);
  color:rgba(0, 0, 0, 0.829);
  border: 1px solid rgb(255, 255, 255);
  border-bottom: 2px solid rgb(75, 110, 75);
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
}
#filter select{
   border: 1px solid rgb(255, 255, 255);
  -moz-appearance:none; /* Firefox */
  -webkit-appearance:none; /* Safari and Chrome */
  appearance:none;
}

</style>
