<template>
  <div class="job-config-master-container">
    <h1>Job Config</h1>
    <h2  v-if="!jobConfigLoading" class="job-config-h2">{{jobConfig.definition.name}}</h2>
    <div v-if="jobConfigLoading">
      <h2><font-awesome-icon :icon="['fas', 'spinner']"/></h2>
      <h2 class="job-config-h2">Details</h2>
      <div>
        <div class="job-config-row">
          <div class="col">
            <div><strong>Name: </strong><font-awesome-icon :icon="['fas', 'spinner']"/></div>
            <div><strong>Source: </strong><font-awesome-icon :icon="['fas', 'spinner']"/></div>  
            <div><strong>Type: </strong><font-awesome-icon :icon="['fas', 'spinner']"/></div>
          </div>
          <div class="col">
            <div><strong>Value: </strong>   <font-awesome-icon :icon="['fas', 'spinner']"/></div>  
          </div>
        </div>
      </div>
      <h2 class="job-config-h2">Variables:</h2> 
      <table id="job-config-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Source</th>
            <th>Type</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody v-if="jobConfig==undefined && !jobConfigLoading">        
          <tr>
            <td><font-awesome-icon :icon="['fas', 'spinner']"/></td>  
            <td><font-awesome-icon :icon="['fas', 'spinner']"/></td>
            <td><font-awesome-icon :icon="['fas', 'spinner']"/></td>
            <td><font-awesome-icon :icon="['fas', 'spinner']"/></td>
          </tr>
        </tbody>
      </table>           
    </div>
    <div v-if="!jobConfigLoading && jobConfig!==undefined"  class="job-config-container">
      <h2 class="job-config-h2">Details</h2>
      <div class="job-config-row">
        <div class="col">
          <div><strong>Definition Name: </strong>{{ jobConfig.definition.name  }} </div>
          <div><strong>Source: </strong>{{ jobConfig.definition.source  }} </div>
        </div>
        <div class="col">
          <div><strong>Type: </strong>{{ jobConfig.definition.type  }}</div>
          <div><strong>Version: </strong>{{ jobConfig.definition.version  }} </div>
        </div>
      </div>
      <h2 class="job-config-h2">Variables</h2> 
      <table id="job-config-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Source</th>
            <th>Type</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody v-if="!isLoading">        
          <tr v-for="variable, index in jobConfig.variables" :key="index">
            <td>{{variable.name}}</td>  
            <td>{{variable.source}}</td>
            <td>{{variable.type}}</td>
            <td>{{variable.value}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>  
</template>

<script>
import {mapGetters, mapActions} from 'vuex'

export default {
  name: 'JobConfigReadOnly',
  props: {
    project: String,
    workflow_id: String,
    step: String,
    job_id: String,
  },
  computed: {
    ...mapGetters({
      jobConfig: 'getJobConfig',
      jobConfigLoading: 'jobConfigLoading',
    })
  },
  methods: {
    ...mapActions(['loadJobConfig'])
  },
  async mounted(){
    let args = {project: this.project, workflow_id: this.workflow_id, step: this.step, job_id: this.job_id}
    await this.loadJobConfig(args)
  }
}
</script>
<style scoped>
a {
  text-decoration: none;
}

.loading-component {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
    width: 100px;
    height: 100px;
}

.col{
    flex:1;
    flex-wrap: wrap;
    justify-content: flex-start;
    flex-direction: column;
    box-sizing: border-box;
    line-height: 2.5;
  }
  
  .job-config-container {
    text-align: center;
  }

  .job-config-row{
    display: flex;
    text-align: center;
    border: 0.5px solid lightgray;
    width:90%;
    margin-left:5%
  }

  .job-config-row:nth-child(odd) {
  background-color: rgb(0, 75, 100, 0.05);
  }
  .job-config-row:hover{
    background-color: rgba(100, 105, 110, 0.1);
    cursor:pointer;
  }

  .job-config-h2 {
    padding-top:0.5rem;
    padding-bottom:1rem;
    margin:1rem;
    background: rgba(0, 0, 0, 0.01);
    border:0.1rem solid rgba(0, 0, 0, 0.03);
    border-radius:0.25rem;
    color:rgb(50, 50, 50)
  }

</style>
