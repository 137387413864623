<template>
  <div class="job-config-master-container">
    <div v-if="this.status==='UNKNOWN'|| this.status==='PENDING'|| this.status==='RUNNABLE'">
      <JobConfigEditableVue :project="this.project" :workflow_id="this.workflow_id" :step="this.step" :job_id="this.job_id"/>
    </div>
    <div v-else>
      <JobConfigReadOnlyVue :project="this.project" :workflow_id="this.workflow_id" :step="this.step" :job_id="this.job_id"/>
    </div>
  </div>  
</template>

<script>
import JobConfigEditableVue from './JobConfigEditable.vue'
import JobConfigReadOnlyVue from './JobConfigReadOnly.vue'

export default {
  components: {
    JobConfigEditableVue,
    JobConfigReadOnlyVue
  },
  props: {
    project: String,
    workflow_id: String,
    step: String,
    job_id: String,
    status:String
  },
}
</script>
<style scoped>

</style>
